import { Controller } from "@hotwired/stimulus";
import { getSession } from "../apiService";
import { config } from '../utils/config';

export default class extends Controller {
  static targets = ["messages"];
  static values = { chatId: String };

  initialize() {
    this.isConnected = false;
  }

  connect() {
    this.isConnected = true;

    this.setLoadingState();

    // If chatIdValue is already set, load the chat
    if (this.hasChatIdValue) {
      this.loadChat(this.chatIdValue);
    }
  }

  chatIdValueChanged() {
    // Wait until the controller is connected
    if (this.isConnected) {
      this.loadChat(this.chatIdValue);
    } else if (this.chatIdValue) {
      // If not connected yet, delay the loading until after connection
      this.waitingChatId = this.chatIdValue;
    }
  }

  async loadChat(sessionId) {
    this.setLoadingState();

    // Store the sessionId being requested
    this.currentSessionId = sessionId;

    try {
      const { response, responseData } = await getSession({
        botId: config.botId,
        sessionId: sessionId,
        jwt: config.jwt,
      });

      // Check if the sessionId is still the current one before updating the UI
      if (this.currentSessionId !== sessionId) {
        return;
      }

      if (!response.ok) {
        throw new Error(responseData.detail || 'Failed to load chat history');
      }

      this.dispatch("messagesLoaded", {
        detail: { messages: responseData["messages"] },
      });
    } catch (error) {
      console.error('Failed to load chat:', error);
      this.setErrorState();
    }
  }

  setLoadingState() {
    this.messagesTarget.innerHTML = `
      <div class="flex flex-col items-center mt-4">
        <svg class="animate-spin ml-3 h-5 w-5 text-purple" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span>Loading previous chats...</span>
      </div>
    `;
  }

  setErrorState() {
    this.messagesTarget.innerHTML = `
      <div class="flex flex-col items-center mt-4 text-red-600">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span class="mt-2">Failed to load chat history. Please try again later.</span>
      </div>
    `;
  }
}
