export const getCurrentTimeAMPM = () => {
  const now = new Date();
  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  return `${hours}:${minutes} ${ampm}`;
};

export const formatHistoryDateTime = (dateTimeStr) => {
  // Parse the ISO 8601 datetime string
  const date = new Date(dateTimeStr);

  // Get components adjusted to user's local timezone
  const day = date.getDate();
  const month = date.getMonth(); // Months are zero-based in JavaScript
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Get current year to decide whether to include the year
  const currentYear = new Date().getFullYear();
  const includeYear = year !== currentYear;

  // Month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthName = monthNames[month];

  // Convert hours to 12-hour format and determine am/pm
  let hour = hours % 12 || 12; // Converts '0' or '12' to '12'
  const period = hours >= 12 ? 'pm' : 'am';

  // Pad minutes with leading zero if necessary
  const minuteStr = minutes.toString().padStart(2, '0');

  // Build the formatted time
  const formattedTime = `${monthName} ${day}${includeYear ? ' ' + year : ''} ${hour}:${minuteStr}${period}`;

  return formattedTime;
}