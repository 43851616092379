// messages_controller.js
import { Controller } from "@hotwired/stimulus";

import BotChatBubble from './../components/BotChatBubble';
import { formatHistoryDateTime } from './../helpers/timestamp';

export default class extends Controller {
  static values = { messages: Array };
  static targets = ["list"];

  initialize() {
    this.isConnected = false;
  }

  connect() {
    this.isConnected = true;
  }

  messagesValueChanged() {
    if (this.isConnected) {
      this.renderMessages();
    } else {
      this.waitingMessagesValue = this.messagesValue;
    }
  }

  updateMessages(event) {
    if (!event?.detail?.messages) {
      console.error('Invalid messages data received');
      return;
    }
    const { messages } = event.detail;
    this.messagesValue = messages;
  }

  renderMessages() {
    // Clear existing messages
    this.listTarget.innerHTML = '';

    // Render each message
    this.messagesValue.forEach((message) => {
      if (message.actor === 'bot') {
        this.addBotChatBubble(message);
      } else {
        this.addUserChatBubble(message);
      }
    });

    this.listTarget.scrollIntoView({
      behavior: 'instant',
      block: 'end'
    });
  }

  addUserChatBubble(data) {
    // Import the HTML file using require
    const chatBubbleHTML = require('./../chatBubble.html').default;

    // Create a temporary div to hold the parsed HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = chatBubbleHTML;

    // Extract the first child of the temporary div (the created HTML)
    const chatBubbleElement = tempDiv.firstElementChild;

    chatBubbleElement.querySelector('#chat-bubble-text').textContent = data.text;
    chatBubbleElement.querySelector('#chat-bubble-timestamp').textContent = formatHistoryDateTime(data.timestamp);

    this.listTarget.appendChild(chatBubbleElement);

  }
  addBotChatBubble(data) {
    const chatBubble = new BotChatBubble();
    chatBubble.setBotResponse(data.text, formatHistoryDateTime(data.timestamp));
    this.listTarget.appendChild(chatBubble.element);
  }

}