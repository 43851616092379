class ConfigurationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ConfigurationError';
  }
}

export class Config {
  constructor() {
    this.urlParams = new URLSearchParams(window.location.search);

    // Required parameters with their validation patterns
    this.required = {
      botId: {
        value: null,
        pattern: /^[A-Za-z0-9\-]+$/  // alphanumeric and hyphens
      },
      jwt: {
        value: null,
        pattern: /^[A-Za-z0-9\-_\.]+$/  // base64url chars and dots
      }
    };

    // Optional parameters with defaults
    this.optional = {
      initialMessage: {
        value: "Hello! I'm a chatbot here to help you with any questions you might have. Feel free to ask me anything! 🤖",
        pattern: null  // null pattern means use basic string sanitization
      },
      title: {
        value: "AI Chatbot",
        pattern: null
      }
    };
  }

  sanitize(value, pattern = null) {
    if (!value) return value;

    // Basic string sanitization for XSS prevention
    const div = document.createElement('div');
    div.textContent = value;
    const sanitizedValue = div.textContent;

    // If a pattern is provided, only keep matching characters
    if (pattern) {
      return sanitizedValue.replace(new RegExp(`[^${pattern.source}]`, 'g'), '');
    }

    return sanitizedValue;
  }

  initialize() {
    const missingParams = [];

    // Handle required parameters
    Object.entries(this.required).forEach(([param, config]) => {
      const value = this.urlParams.get(param);
      if (!value) {
        missingParams.push(param);
      } else {
        config.value = this.sanitize(value, config.pattern);
      }
    });

    if (missingParams.length > 0) {
      throw new ConfigurationError(
        `Required parameters missing: ${missingParams.join(', ')}`
      );
    }

    // Handle optional parameters
    Object.entries(this.optional).forEach(([param, config]) => {
      const value = this.urlParams.get(param);
      if (value) {
        config.value = this.sanitize(value, config.pattern);
      }
    });
  }

  hasParam(name) {
    return this.urlParams.has(name);
  }

  getParam(name) {
    const value = this.urlParams.get(name);
    return this.sanitize(value);
  }

  get botId() {
    return this.required.botId.value;
  }

  get jwt() {
    return this.required.jwt.value;
  }

  get initialMessage() {
    return this.optional.initialMessage.value;
  }

  get title() {
    return this.optional.title.value;
  }
}

export const config = new Config();