import { Controller } from "@hotwired/stimulus"
import { getMessages } from "../apiService";
import { config } from '../utils/config';
export default class extends Controller {
  static targets = ["list", "newChat"];
  static outlets = ["chat-content"]

  // Property to keep track of the currently selected chat element
  currentChatElement = null;

  connect() {
    this.setLoadingState();
    this.loadChats();
  }

  async loadChats() {
    try {
      const { response, responseData } = await getMessages({
        botId: config.botId,
        jwt: config.jwt,
      });

      this.clearLoadingState();

      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }

      // Group chats by month
      const chatsByMonth = {};

      if (!responseData || !Array.isArray(responseData)) {
        return;
      }

      responseData.forEach(chat => {
        // Skip chats without a title
        if (!chat.title) return;

        const event = new Date(chat.last_request_time);
        const month = event.toLocaleString('en-US', { month: 'long', year: 'numeric' });

        // If the month group doesn't exist, create it
        if (!chatsByMonth[month]) {
          chatsByMonth[month] = [];
        }

        // Add the chat to the appropriate month group
        chatsByMonth[month].push(chat);
      });

      // Iterate through each month and append the month heading and chat items
      Object.keys(chatsByMonth).forEach(month => {
        // Create and append the month heading
        const monthHeading = document.createElement('div');
        monthHeading.textContent = month;
        monthHeading.classList.add('month-heading');
        this.listTarget.appendChild(monthHeading);

        // Create a new <ul> for the current month
        const chatList = document.createElement('ul');
        chatList.classList.add('chat-list');

        // Append each chat item to the new <ul>
        chatsByMonth[month].forEach(chat => {
          const item = document.createElement('li');
          item.classList.add('chat-item');
          item.textContent = chat.title;
          item.dataset.date = new Date(chat.last_request_time).toLocaleString('en-US');
          item.dataset.action = "click->chat-list#selectChat";
          item.dataset.chatId = chat.uuid;
          chatList.appendChild(item);
        });

        // Append the <ul> to the list target
        this.listTarget.appendChild(chatList);
      });
    } catch (error) {
      console.error('Failed to fetch messages:', error);
      this.setErrorState();
    }
  }

  newChat(event) {
    // Remove 'selected' class from the previously selected item
    if (this.currentChatElement) {
      this.currentChatElement.classList.remove('selected');
      this.currentChatElement = null;
    }

    event.currentTarget.classList.add('selected');

    if (this.hasChatContentOutlet) {
      this.chatContentOutlet.newChat();
    } else {
      console.error("No chat content outlet found");
    }
  }

  selectChat(event) {
    const chatId = event.currentTarget.dataset.chatId;

    // Remove 'selected' class from the previously selected item
    if (this.currentChatElement) {
      this.currentChatElement.classList.remove('selected');
    }

    this.newChatTarget.classList.remove('selected');

    // Add 'selected' class to the newly selected item
    event.currentTarget.classList.add('selected');

    // Update the reference to the currently selected chat element
    this.currentChatElement = event.currentTarget;

    if (this.hasChatContentOutlet) {
      this.chatContentOutlet.chatSelected(chatId);
    } else {
      console.error("No chat content outlet found");
    }
  }

  setErrorState() {
    this.listTarget.innerHTML = `
      <div class="flex flex-col items-center">
        <p>Failed to load previouschats. Please try again.</p>
      </div>
    `;
  }

  setLoadingState() {
    this.listTarget.innerHTML = `
      <div class="flex flex-col items-center">
        <svg class="animate-spin ml-3 h-5 w-5 text-purple" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span>Loading previous chats...</span>
      </div>
    `;
  }

  clearLoadingState() {
    this.listTarget.innerHTML = "";
  }
}
