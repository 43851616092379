import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['historicalChat', 'conversationalChat'];

  newChat() {
    this.conversationalChatTarget.classList.remove('hidden');
    this.historicalChatTarget.classList.add('hidden');
  }

  chatSelected(chatId) {
    this.conversationalChatTarget.classList.add('hidden');
    this.historicalChatTarget.classList.remove('hidden');

    this.historicalChatTarget.setAttribute("data-historical-chat-chat-id-value", chatId);
  }
}
