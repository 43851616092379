import './main.css';
import { config } from "./utils/config";
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

// Import images
import './images/codepath_logo_1000w.png';
import './images/codepath-1x1_icon-white.png';
import './images/codepath-1x1_icon-dark.png';

try {
  // Initialize configuration immediately
  config.initialize();

  window.chatWidgetConfig = config;

  // START: Add Google Analytics
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', 'G-1PHVHG6XHX', {
    'bot_id': config.botId
  });

  window.gtag = gtag;
  // END: Add Google Analytics

  window.Stimulus = Application.start()
  const context = require.context("./controllers", true, /\.js$/)
  Stimulus.load(definitionsFromContext(context))
} catch (error) {
  const container = document.getElementById('chat-widget-container');
  if (container) {
    container.innerHTML = `
      <div class="h-screen flex items-center justify-center p-4">
        <div class="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative max-w-md w-full">
          <strong class="font-bold">Configuration Error</strong>
          <p class="mt-2">${error.message}</p>
          <p class="mt-2 text-sm">Please ensure the chat widget is properly configured with required parameters.</p>
        </div>
      </div>
    `;
  }
}
